import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    livePreviewURL: false,
  },
  mutations: {
    setLivePreviewURL(state, livePreviewURL) {
      state.livePreviewURL = livePreviewURL;
    },
  }
});