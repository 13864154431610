<template>
  <v-list one-line>
    <v-row md="12">
      <v-col md="12">
        <v-text-field
          v-if="(!options.subHeaderSelection && !options.hasOwnProperty('noImages'))"
          label="Override Section Header"
          v-model="selectedHeader"
          hide-details
          dense
          @change="emitSelectionUpdates"
        ></v-text-field>
        <v-combobox
          v-if="options.subHeaderSelection"
          label="Override Section Header"
          v-model="selectedHeader"
          :items="options.subHeaderSelection"
          hide-details
          dense
          @change="emitSelectionUpdates"
          >
        </v-combobox>
        <v-text-field
          v-if="options.hasHeaderImage"
          v-model="selectedHeaderImageUrl"
          label="Header Image Url"
          class="pt-5"
          hide-details
          dense
          @change="checkImageSize"
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <v-progress-circular
                v-if="checkingImageSize"
                size="24"
                color="info"
                indeterminate
              ></v-progress-circular>
              <AlertIcon
                v-else-if="headerImageAlertLevel"
                :message="headerImageAlertMessage"
                :level="headerImageAlertLevel"
              ></AlertIcon>
            </v-fade-transition>
          </template>
        </v-text-field>
        <v-text-field
          v-if="options.hasLogoImage"
          v-model="selectedLogoImageUrl"
          label="Logo Image Url"
          class="pt-5"
          hide-details
          dense
          @change="checkLogoSize"
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <v-progress-circular
                v-if="checkingLogoSize"
                size="24"
                color="info"
                indeterminate
              ></v-progress-circular>
              <AlertIcon
                v-else-if="headerLogoAlertLevel"
                :message="headerLogoAlertMessage"
                :level="headerLogoAlertLevel"
              ></AlertIcon>
            </v-fade-transition>
          </template>
        </v-text-field>
        <v-text-field
          v-if="options.hasReferral"
          v-model="selectedReferralUrl"
          label="Referral Url"
          class="pt-5"
          hide-details
          dense
          @change="emitSelectionUpdates"
        ></v-text-field>
        <v-textarea
            v-if="options.showtext"
            v-model="surveyhtml"
            label="Content HTML"
            rows="6"
            auto-grow
            @change="$emit('updateSurvey', {id: section_id, surveyhtml: surveyhtml})"
        ></v-textarea>
      </v-col>
    </v-row>

      <v-row 
          md="12"
          v-if="selectedItems.length<1"
      >
        <v-col md="8">
          <v-combobox
            v-if="!options.noCategory"
            clearable
            dense
            multiple
            small-chips
            label="Select one or multiple category"
            :items="topics"
            item-text="display"
            item-value="id"
            v-model="selectedTopics"
            @change="emitSelectionUpdates"
          ></v-combobox>
        </v-col>
        <v-col md="2">
          <v-text-field
            class="pa-0 ma-0"
            v-if="!options.noCount"
            label="Count"
            v-model="selectedCount"
            hide-details
            single-line
            type="number"
            min="0"
            :max="options.maxCount ? options.maxCount : ''"
            @change="emitSelectionUpdates"
          ></v-text-field>
        </v-col>
        <v-col md="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!options.noCuration"
              class="mx-2"
              dark
              small
              v-bind="attrs"
              v-on="on"
              @click="$emit('resetCurateContent', {id: section_id, selectedTopics: selectedTopics, selectedCount: selectedCount})"
            >
            <v-icon dark>
              mdi-playlist-edit
            </v-icon>
            </v-btn>
          </template>
          <span>Click to edit default listing</span>
        </v-tooltip>
        </v-col>
      </v-row>
    <template v-if="!options.noCuration">
      <v-row md="12">
        <v-col>
          <v-subheader>Curated Story ({{selectedItems.length}})</v-subheader>
        </v-col>
        
        <v-col align="right">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="indigo"
            @click="$emit('curateContent', {id: section_id, selectedTopics: selectedTopics})"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <draggable sort=true draggable=".list-group-item" group="story" @end="onDone">
        <v-list-item
          v-for="(item,index) in selectedItems"
          class="list-group-item"
          :key="item.id">
          <StoryItem
            :item="item"
            :key="item.id"
          ></StoryItem>
          <v-btn
            class="mx-0"
            fab
            dark
            x-small
            color="indigo"
            @click="$emit('removeContent', {id: item.id, section_id: section_id, index: index})"
          >
            <v-icon dark>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </v-list-item>
      </draggable>
    </template>
  </v-list>
</template>

<script>
import StoryItem from './StoryItem';
import draggable from 'vuedraggable';
import AlertIcon from './AlertIcon';

export default {
  name: "curated-list",
  components: {
    StoryItem,
    draggable,
    AlertIcon,
  },

  props: {
    'selectedItems': null,
    'topics': null,
    'section_id': null,
    'defaultVal': null,
    options: {
      type: Object,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return { noCount: false, noCategory: false, noCuration: false, subHeader: '' }
      }
    },
  },

  data() {
    return {
      selectedTopics: [],
      selectedCount: 0,
      selectedHeader: '',
      surveyhtml: '',
      selectedHeaderImageUrl: '',
      selectedLogoImageUrl: '',
      selectedReferralUrl: '',
      maxHeaderImageWidth: 780,
      maxLogoImageWidth: 600,
      headerImageAlertMessage: null,
      headerImageAlertLevel: null,
      headerLogoAlertMessage: null,
      headerLogoAlertLevel: null,
      checkingImageSize: false,
      checkingLogoSize: false,
    };
  },

  watch: {
    defaultVal: function () { this.setDefault(); },
  },

  mounted() {
    this.setDefault();
  },

  methods: {
    getImageDimensions(url) {
      return new Promise((resolve, reject) => {
        if (url) {
          this.headerImageAlertLevel = null;
          let img = new Image();
          img.onload = () => resolve(img);
          img.onerror = () => reject();
          img.src = url;
        } else {
          resolve();
        }
      });
    },

    checkImageSize(event) {
      this.checkingImageSize = true;
      this.getImageDimensions(event)
        .then((image) => {
          if (image && image.width > this.maxHeaderImageWidth) {
            this.headerImageAlertMessage = `Image is wider than ${this.maxHeaderImageWidth} and will be compressed to fit.<br/>Larger images will take longer for the user to load.`;
            this.headerImageAlertLevel = 'warn';
          } else {
            this.headerImageAlertMessage = null;
            this.headerImageAlertLevel = null;
          }
        })
        .catch(() => {
          this.headerImageAlertMessage = 'Image could not be loaded';
          this.headerImageAlertLevel = 'error';
        })
        .finally(() => {
          this.checkingImageSize = false;
        });
      this.emitSelectionUpdates();
    },

    checkLogoSize(event) {
      this.checkingLogoSize = true;
      this.getImageDimensions(event)
        .then((image) => {
          if (image && image.width > this.maxLogoImageWidth) {
            this.headerLogoAlertMessage = `Logo is wider than ${this.maxLogoImageWidth} and will be compressed to fit.<br/>Larger images will take longer for the user to load.`;
            this.headerLogoAlertLevel = 'warn';
          } else {
            this.headerLogoAlertMessage = null;
            this.headerLogoAlertLevel = null;
          }
        })
        .catch(() => {
          this.headerLogoAlertMessage = 'Logo could not be loaded';
          this.headerLogoAlertLevel = 'error';
        })
        .finally(() => {
          this.checkingLogoSize = false;
        });
      this.emitSelectionUpdates();
    },

    emitSelectionUpdates() {
      this.$emit('updateSelected', 
        {
          id: this.section_id, 
          selectedTopics: this.selectedTopics, 
          selectedCount: this.selectedCount, 
          selectedHeader: this.selectedHeader, 
          selectedHeaderImageUrl: this.selectedHeaderImageUrl,
          selectedLogoImageUrl: this.selectedLogoImageUrl,
          selectedReferralUrl: this.selectedReferralUrl
        }
      );
    },

    setDefault() {
      this.selectedCount = this.defaultVal.count;
      this.selectedTopics = [];
      this.selectedHeader = this.defaultVal.header ?? this.options.subHeader;
      this.selectedHeaderImageUrl = this.defaultVal.selectedHeaderImageUrl ?? this.options.headerImage;
      this.selectedLogoImageUrl = this.options.logoImage;
      this.selectedReferralUrl = this.options.ReferralUrl;
      this.surveyhtml = this.defaultVal.surveytext ? this.defaultVal.surveytext : '';
      this.defaultVal.topics.forEach(element => {
        var matchObj = this.topics.filter(function(elem){
          if(element && elem.id == element) return elem;
        });
        if (matchObj[0]) this.selectedTopics.push(matchObj[0]);
      }); 
    },

    onDone: function(evt) {
      this.$emit('sortCurated', this.section_id, evt.oldIndex, evt.newIndex);
    },
  },
};
</script>

<style>
  .v-textarea {
    font-size: 0.8em !important;
  }
  .v-textarea textarea {
    margin-top: 10px !important;
    line-height: 17px !important;
  }
</style>