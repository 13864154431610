<template>
  <v-list-item-content>
    <v-list-item v-html="item.title"
      dense
      class="pl-0 ml-0 listitem"
      :href="livePreviewURL ? item.liveUrl : item.url"
      target="_blank"></v-list-item>
    <v-list-item-subtitle class="listitemdesc">
      <v-row>
        <v-col md="6">Type: {{ item.type }}</v-col>
        <v-col align="right" md="6">Published: {{ item.date_gmt | formatDate }} GMT</v-col>
      </v-row>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import { mapState} from "vuex";
export default {
  id: "id",
  name: "story-item",
  props: {
    'item': null,
  },
  computed: mapState({
    livePreviewURL(){
      return this.$store.state.livePreviewURL;
    },
  }),
};
</script>

<style lang="scss" scoped>
    .listitem {
      font-size: 14px;
      font-weight: bold;
      color: black;
    }
    .listitemdesc {
      font-size: 12px;
      font-weight: bold;
      color: black;
    }
</style>