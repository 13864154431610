<template>
  <v-tooltip
    v-model="show"
    top
  >
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" :color="color">
        {{ icon }}
      </v-icon>
    </template>
    <span v-html="message"></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "alert-icon",

  props: {
    message: null,
    level: null
  },

  data() {
    return {
      show: false,
      errorColor: 'red',
      warnColor: 'yellow darken-1',
      errorIcon: 'mdi-alert-circle',
      warnIcon: 'mdi-help-circle'
    };
  },

  computed: {
    color() {
      return this.level == 'error' ? this.errorColor : this.warnColor;
    },

    icon() {
      return this.level == 'error' ? this.errorIcon : this.warnIcon;
    }
  }

}
</script>
