<template>
  <v-app>
    <v-navigation-drawer :width="600"
    :permanent="!$vuetify.breakpoint.mdAndUp"
    fixed app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h3">
            Newsletters Builder
            <div v-if="!isSignIn" id="signin_button"></div>
          </v-list-item-title>
          <v-list-item-subtitle v-if="isSignIn">
            Logged in: {{ email }} <v-btn small @click="handleClickSignOut" elevation="2" v-if="isSignIn">Sign Out</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider dark class="light-green lighten-1"></v-divider>
      <v-list
        dense
        nav
        v-if="isSignIn"
      >
        <v-list-item v-if="isSignIn">
          <v-select
            :items="brands"
            label="Brand"
            v-model="selectedBrand"
          ></v-select>
        </v-list-item>
        <v-list-item>
          <v-select
            :items="ecodes"
            item-text="label"
            item-value="filename"
            label="Select an eCode"
            v-model="selectedEcode"
          ></v-select>
        </v-list-item>
        <v-list-item>
          <v-select
            :items="layouts"
            label="Apply Layout"
            @input="updateLayout"
            v-model="selectedLayout"
            ></v-select>
        </v-list-item>
        <v-list-item>
          <div v-if="selectedEcode">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(option) in options"
                :key="option.id"
              >
              <v-expansion-panel-header class="light-green lighten-3">
                <v-switch
                  v-model="sectionEnabled[option.id-1]"
                  @change="constructPreviewURL"
                  @click.stop="doNothing"
                  :label="option.sectionTitle"
                  hide-details
                  class="expansion-switch"
                ></v-switch>
                <v-divider vertical class="mr-5"/>
              </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-2">
                  <CuratedList
                    v-if="curatedFullStory"
                    :selectedItems="curatedFullStory[option.id-1]"
                    :topics="topics"
                    :defaultVal="defaultSectionValue[option.id-1]"
                    :options="option"
                    @updateSelected="updateSelected"
                    @curateContent="curateContent"
                    @sortCurated="sortCurated"
                    @removeContent="removeContent"
                    @resetCurateContent="resetCurateContent"
                    @updateSurvey="updateSurvey"
                    :section_id="option.id"
                  ></CuratedList>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-checkbox
              v-model="renderMedusa"
              label="Render Medusa"
              color="red"
              value="red"
              hide-details
              @change="constructPreviewURL"
            ></v-checkbox>
            <!-- <v-checkbox
              :value="$store.state.livePreviewURL"
              label="Live Preview URL"
              color="red"
              hide-details
              @change="setLivePreviewURL($event); constructPreviewURL()">
            </v-checkbox> -->
            <v-checkbox
              v-model="debug"
              label="Debug Mode"
              color="red"
              value="red"
              hide-details
              @change="constructPreviewURL"
            ></v-checkbox>
          </div>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main
      v-if="selectedEcode && isSignIn"
      >
        <v-container fluid>
          <div class="form-control wizard-form-control d-flex align-items-center url-code px-20px mb-10px">
                <span class="title">Creative URL:</span>
                <span class="title url">{{ previewURL }}</span>
                <input type="hidden" id="url-code" :value="previewURL">
                <v-spacer />
                <v-btn
                  depressed
                  color="error"
                  @click="copyText"
                >Copy</v-btn>
          </div>
          <v-card>
              <!-- HERE -->
              <iframe id="preview" :src="previewURL"
                frameborder="0" allowfullscreen
                style="position:relative;top:0;left:0;width:100%;height:90vh;">
              </iframe>
          </v-card>
        </v-container>
    </v-main>
  <v-dialog
        v-model="dialog"
        max-width="1000"
        width="1000"
        v-if="dialogData"
      >
        <v-card
        :loading="loading">
          <v-card-title>
            <v-row md="12">
              <v-col md="4">
              <div>Curate {{ section }} </div>
              <v-combobox
                clearable
                small-chips
                label="Content Type"
                :items="contentType"
                item-value="value"
                item-text="name"
                v-model="searchCType"
              ></v-combobox>
              <v-combobox
                clearable
                multiple
                small-chips
                label="Category"
                :items="topics"
                item-text="display"
                item-value="id"
                v-model="searchTopics"
              ></v-combobox>
              <v-text-field
                v-model="searchKeyword"
                clearable
                flat
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search by Keyword"
              ></v-text-field>
              <v-text-field
                v-model="searchSlug"
                clearable
                flat
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search by Slug"
              ></v-text-field>
              <v-checkbox
                v-model="premium"
                label="Premium Content Only"
                color="purple"
                value="1"
                hide-details
                @change="constructPreviewURL"
              ></v-checkbox>
              <br/>
              <div align="right"><v-btn
              @click="searchContents"
              color="primary">
                Search
              </v-btn>
              </div>
              </v-col>
              <v-col md="8">

              <v-subheader v-if="!searchResults">No results.</v-subheader>
              <v-subheader v-if="debug">{{ searchQuery }}</v-subheader>
              <v-list one-line v-if="searchResults">
                <template>
                  <v-subheader v-if="searchResults">Displaying {{ displayTotal }} of {{resultTotal}} result(s) {{ resultPages>1?'from Page '+searchPageNum:'' }}:</v-subheader>

                  <v-divider></v-divider>
                  <v-list-item
                    v-for="(item) in searchResults"
                    :key="item.id"
                    class="pl-0 ml-0"
                  >
                    <v-list-item-avatar
                      v-if="curatedStory[dialogData.id-1].indexOf(item.id) === -1">
                      <v-btn
                        dark
                        small
                        @click="add2Section(item)"
                        v-if="curatedStory[dialogData.id-1].indexOf(item.id) === -1"
                      >
                      <v-icon dark>
                        mdi-file-plus
                      </v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                    <div
                      class="pr-2 subtitle-1 font-weight-thin"
                      v-if="curatedStory[dialogData.id-1].indexOf(item.id) !== -1"
                      >Added</div>
                    <StoryItem
                      :item="item"
                    ></StoryItem>
                  </v-list-item>
                </template>

                <template>
                  <div class="text-center">
                    <v-pagination
                      v-model="searchPageNum"
                      :length="resultPages"
                      @input="searchContents"
                      :total-visible="7"
                    ></v-pagination>
                  </div>
                </template>
              </v-list>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
      v-model="snackbar"
      :color="snackbartype"
      :timeout="timeout"
    >
      {{ snackbartext }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import CuratedList from './components/CuratedList';
import StoryItem from './components/StoryItem';
import axios from 'axios';
import moment from 'moment';
import Vue from 'vue';
import { mapMutations } from "vuex";
import jwt_decode from "jwt-decode";

Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mmA')
  } else
    return '--'
});

export default {
  name: 'App',
  components: {
    CuratedList,
    StoryItem,
  },

  data: () => ({
    brands: ['TechRepublic'],
    selectedBrand: 'TechRepublic',
    ecodes: [],
    selectedEcode: null,
    layouts: ['Default','Premium', 'PremiumNew', 'TopStory', 'TopStoryNew','TopStoryNewTest', 'PremiumTest', 'NewTemplate', 'NewTemplateAd'],
    selectedLayout: 'Default',
    topics: [],
    sectionEnabled: [],
    defaultSectionValue: [],
    contentType: '',
    curatedStory: [[], [], [], [], [], []],
    curatedFullStory: [[], [], [], [], [], []],
    baseURL: 'https://creatives.techrepublic.com/newsletters/techrepublic/',
    previewURL: null,
    renderMedusa: null,
    debug: null,
    dialog: false,
    dialogData: null,
    searchCType: null,
    searchTopics: null,
    searchKeyword: null,
    searchId: null,
    searchSlug: null,
    searchResults: null,
    searchPerPage: 7,
    searchPageNum: 1,
    searchedCType: null,
    searchedKeyword: null,
    searchedPremium: '',
    searchedSlug: null,
    searchedTopics: null,
    isInit: false,
    isSignIn: false,
    email: null,
    name: null,
    numberOfSections: 0,
    loading: false,
    searchQuery: null,
    bitlyurl: null,
    resultPages: 0,
    resultTotal: 0,
    displayTotal: 0,
    options: [],
    surveyText: '',
    timeout: 30000,
    snackbar: false,
    snackbartext: '',
    snackbartype: 'success',
    errorMessage: '',
    premium: '',
  }),

  mounted() {
    let googleScript = document.createElement('script');
    googleScript.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(googleScript);

    window.addEventListener('load', () => {
        // console.log(window.google);
        window.google.accounts.id.initialize({
            client_id: "686353930283-v0muofg72qhob1riimkr8ren355su43p.apps.googleusercontent.com",
            callback: this.handleCredentialResponse
        });
        window.google.accounts.id.renderButton(
            document.getElementById("signin_button"),
            { theme: "outline", size: "small", type: "standard", auto_select: "true" }  // customization attributes
        );
        // window.google.accounts.id.prompt();
    })

    this.getECodes(this.selectedBrand);
    this.getCategory();
    this.getContentType();
    this.getSurveyText();
    this.setDefaultOptions(this.selectedLayout);
    //console.log(this.options);
  },

  watch: {
    errorMessage: {
      handler(val) {
        if (val)
        {
          console.log('errorMessage', val);
          this.snackbartext = val;
          this.snackbartype = 'error';
          this.snackbar = true;
          this.errorMessage = '';
        }
      }
    },
    selectedBrand: function (val) { this.getECodes(val); },
    selectedEcode: function (val) { this.updateDefaultEcodeConfig(val); this.constructPreviewURL(); },
  },

  computed: {
    section(){
      let section = this.options.find(op => op.id === this.dialogData.id)

      return section.sectionTitle
    },
  },

  methods: {
    ...mapMutations({
      setLivePreviewURL: "setLivePreviewURL",
    }),

    handleCredentialResponse(response) {
      const responsePayload = jwt_decode(response.credential);
      if (responsePayload.hd != 'technologyadvice.com') {
        this.snackbartext = 'Unable to login. Please use TA email!';
        this.snackbartype = 'error';
        this.snackbar = true;
        this.isSignIn = false;
        window.google.accounts.id.prompt();
      } else {
        this.snackbar = false;
        this.email = responsePayload.email;
        this.name = responsePayload.name;
        this.isSignIn = true;
      }
    },

    async handleClickSignOut(){
      this.isSignIn = false;
      window.google.accounts.id.disableAutoSelect()
      window.google.accounts.id.prompt();
    },

    setDefaultSectionValues(){
      this.options.forEach(option => {
        this.defaultSectionValue[option.id-1].headerImage = option.headerImage;
        this.defaultSectionValue[option.id-1].logoImage = option.logoImage;
        this.defaultSectionValue[option.id-1].ReferralUrl = option.ReferralUrl;
        if(option.count){
          this.defaultSectionValue[option.id-1].count = option.count
        }
      })
    },

    setDefaultOptions (layout) {
      switch (layout) {
        case 'TopStory':
          this.options = [
            { id: '1',
              sectionTitle: 'Section 1: Top Story',
              hasHeaderImage: true,
              // Default, pre-populated image
              headerImage: "https://creatives.techrepublic.com/newsletters/techrepublic/images/MY2022-Beijing-Winter-Olympics-app-1.jpg"
            },
            { id: '3', sectionTitle: 'Section 2: Featured By',
              hasHeaderImage: true,
              hasLogoImage: true },
            { id: '2', sectionTitle: 'Section 3: Additional Stories' },
            { id: '5', sectionTitle: 'Section 4: Referral Program',
              noCount: true,
              noCategory: true,
              noCuration: true,
              hasReferral: false,
              subHeader: 'Our Brand New Referral Program'
              },
            { id: '4', sectionTitle: 'Section 5: Survey Form',
              noCount: true,
              noCategory: true,
              noCuration: true,
              subHeader: 'Survey Form',
              showtext: true,
              hasHeaderImage: true },
          ]
          //set default header image
          this.setDefaultSectionValues();
          break;
        case 'TopStoryNew':
        case 'TopStoryNewTest':
          this.options = [
            { id: '1',
              sectionTitle: 'Section 1: Top Story',
              hasHeaderImage: true,
              // Default, pre-populated image
              headerImage: "https://creatives.techrepublic.com/newsletters/techrepublic/images/MY2022-Beijing-Winter-Olympics-app-1.jpg"
            },
            { id: '3', sectionTitle: 'Section 2: Featured By (Premium)',
              hasHeaderImage: true,
              headerImage: "https://technologyadv.wpengine.com/wp-content/uploads/2022/10/TRP_email_01.png",
              count: 1,
              //maxCount:1
              },
            { id: '6', sectionTitle: 'Section 3: Trending Now',
              noImages: true },
            { id: '2', sectionTitle: 'Section 4: Additional Stories',
              count: 5
            },
            { id: '5', sectionTitle: 'Section 5: Referral Program',
              noCount: true,
              noCategory: true,
              noCuration: true,
              hasReferral: false,
              subHeader: 'Our Brand New Referral Program'
              },
            { id: '4', sectionTitle: 'Section 6: Survey Form',
              noCount: true,
              noCategory: true,
              noCuration: true,
              subHeader: 'Survey Form',
              showtext: true,
              hasHeaderImage: true },
          ]
          this.updateDefaultEcodeConfig(this.selectedEcode);
          //set default header image
          this.setDefaultSectionValues();
          break;

        case 'PremiumNew':
        case 'PremiumTest':
          this.options = [
            { id: '1',
              sectionTitle: 'Section 1: Top Story',
              hasHeaderImage: true,
              // Default, pre-populated image
              headerImage: "https://creatives.techrepublic.com/newsletters/techrepublic/images/MY2022-Beijing-Winter-Olympics-app-1.jpg"
            },
            { id: '6',
              sectionTitle: 'Section 2: Trending Now',
              noImages: true ,
              count:3
            },
            { id: '2',
              sectionTitle: 'Section 3: Additional Stories',
              count: 3
            },
          ]
          this.updateDefaultEcodeConfig(this.selectedEcode);
          //set default header image
          this.setDefaultSectionValues();
          break;

        case 'NewTemplate':
        this.options = [
            { id: '1',
              sectionTitle: 'Section 1: Top Story',
              hasHeaderImage: true,
              // Default, pre-populated image
              headerImage: "https://creatives.techrepublic.com/newsletters/techrepublic/images/MY2022-Beijing-Winter-Olympics-app-1.jpg"
            },
            { id: '6',
              sectionTitle: 'Section 2: Trending Now',
              noImages: true ,
              count:3
            },
            { id: '3', sectionTitle: 'Section 3: Premium Resource',
              hasHeaderImage: true,
              headerImage: "https://technologyadv.wpengine.com/wp-content/uploads/2022/10/TRP_email_01.png",
              count: 1,
              },
            { id: '2',
              sectionTitle: 'Section 4: Recommended Readings',
              count: 3
            },
          ]
          this.updateDefaultEcodeConfig(this.selectedEcode);
          //set default header image
          this.setDefaultSectionValues();
          break;

        case 'NewTemplateAd':
        this.options = [
            { id: '1',
              sectionTitle: 'Section 1: Top Story',
              hasHeaderImage: true,
              // Default, pre-populated image
              headerImage: "https://creatives.techrepublic.com/newsletters/techrepublic/images/MY2022-Beijing-Winter-Olympics-app-1.jpg"
            },
            { id: '6',
              sectionTitle: 'Section 2: Trending Now',
              noImages: true ,
              count:3
            },
            { id: '2',
              sectionTitle: 'Section 3: Recommended Readings',
              count: 3
            },
            { id: '3', sectionTitle: 'Section 4: Premium Resource',
              hasHeaderImage: true,
              headerImage: "https://technologyadv.wpengine.com/wp-content/uploads/2022/10/TRP_email_01.png",
              count: 1,
              },
          ]
          this.updateDefaultEcodeConfig(this.selectedEcode);
          //set default header image
          this.setDefaultSectionValues();
          break;

        default:
          this.options = [
            { id: '1', sectionTitle: 'Section 1: Top Story' },
            { id: '2', sectionTitle: 'Section 2: Additional Stories' },
            { id: '5', sectionTitle: 'Section 3: Referral Program',
              noCount: true,
              noCategory: true,
              noCuration: true,
              hasReferral: false,
              subHeader: 'Our Brand New Referral Program'
              },
            { id: '3', sectionTitle: 'Section 4: Multimedia',
              subHeader: 'Multimedia',
              subHeaderSelection: ['Multimedia','TechRepublic Premium','TechRepublic Must-Read Article'] },
            { id: '4', sectionTitle: 'Section 5: Survey Form',
              noCount: true,
              noCategory: true,
              noCuration: true,
              subHeader: 'Our Brand New Referral Program',
              showtext: true },
          ]
          break;
      }
    },
    getSurveyText() {
      axios.get('/newsletters/techrepublic/layout/survey.htm')
        .then((response) => {
          this.surveyText = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error.message+' Endpoint: /newsletters/techrepublic/layout/survey.htm';
          return Promise.reject(error)
        });
    },

    updateSurvey(d) {
      const data = {
            "content" : d.surveyhtml,
            "template_file" : "/direct/newsletters/techrepublic/layout/survey.htm"
          };
      axios.post('/newsletters/updateTemplate.htm?file='+encodeURIComponent(data.template_file)+'&content='+encodeURIComponent(data.content))
        .then(() => {
          // handle success
          this.surveyText = d.surveyhtml;
          this.constructPreviewURL();
          this.snackbartext = 'Survey html updated.';
          this.snackbartype = 'success';
          this.snackbar = true;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error.message;
          return Promise.reject(error)
        });
    },

    convertBitly(url) {
      const data = {
            "long_url" : url,
            "domain" : "bit.ly"
          };
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer edc66f97915662974c7d8bb7c783bd88f95a4967'
          };
      axios.post('https://api-ssl.bitly.com/v4/shorten', data, { headers: headers })
        .then((response) => {
          // handle success
          this.bitlyurl = 'https://creatives.techrepublic.com/newsletters/wrap.htm?url='+encodeURI(response.data.link);
          //console.log('response', response.data.link);
          this.copyTextAction();
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error.message;
          return Promise.reject(error)
        });
    },

    copyTextAction() {
      let CodeToCopy = document.querySelector('#url-code');
      CodeToCopy.setAttribute('type', 'text') ;
      CodeToCopy.setAttribute('value', this.bitlyurl) ;
      CodeToCopy.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        //alert('Creative URL copied ' + msg);
        this.snackbartext = 'Creative URL copied ' + msg;
        this.snackbartype = successful ? 'success' : 'error';
        this.snackbar = true;
      } catch (err) {
        alert('Oops, unable to copy');
        this.snackbartext = 'Oops, unable to copy';
        this.snackbartype = 'error';
        this.snackbar = true;
      }

      /* unselect the range */
      CodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    copyText() {
      let CodeToCopy = document.querySelector('#url-code');
      this.convertBitly(CodeToCopy.value);
    },

    updateDefaultEcodeConfig(f) {

      this.ecodes.forEach(element => {
        if (element.filename == f)
        {
          for (let i = 0; i < this.numberOfSections; i++)
          {
            if (element['section'+(i+1)].count) {
              this.sectionEnabled[i] = element['section'+(i+1)].enable;
              this.defaultSectionValue[i] = {
                topics: element['section'+(i+1)].topics,
                count: element['section'+(i+1)].count,
                header: element['section'+(i+1)].header, //Not currently used, but could be set in '/direct/newsletters/techrepublic/config.htm' at https://ups-template.techrepublic.com/templates/index.htm
                headerImage: element['section'+(i+1)].headerImage, //Not currently used, but could be set in '/direct/newsletters/techrepublic/config.htm' at https://ups-template.techrepublic.com/templates/index.htm
                logoImage: element['section'+(i+1)].logoImage,
                ReferralUrl: element['section'+(i+1)].ReferralUrl
              };
              if (i==3) this.defaultSectionValue[i].surveytext = this.surveyText;
            }
          }
          if (element.layout) this.selectedLayout=element.layout;
        }
      });
    },

    constructPreviewURL() {
      this.previewURL = this.baseURL + 'index.htm?file='+this.selectedEcode;
      this.previewURL += (this.selectedLayout && this.selectedLayout != 'Default') ? '&layout='+this.selectedLayout.toLowerCase() : '' ;
      this.previewURL += (this.renderMedusa) ? '&rendered=1' : '' ;
      this.previewURL += (this.$store.state.livePreviewURL) ? '&livePreviewURL=1' : '' ;
      this.previewURL += `&localDate=${new Date().toLocaleString('en-us',{month:'long', year:'numeric', day:'numeric'})}`;
      this.previewURL += (this.debug) ? '&debugAPI=1' : '' ;
      this.previewURL += '&FORCE_REFRESH=1&time='+Math.random() ;

      var params = [];
      this.options.forEach(option => {
        let IDs=[];
        this.curatedFullStory[option.id-1].forEach(element => {
          IDs.push(element.id+":"+element.type);
        });
        params['section'+option.id] = {
          enable: (this.sectionEnabled[option.id-1]?1:0),
          topics: this.defaultSectionValue[option.id-1].topics,
          count: this.defaultSectionValue[option.id-1].count,
          header: this.defaultSectionValue[option.id-1].header ?? option.subHeader,
          headerImage: this.defaultSectionValue[option.id-1].headerImage ?? option.headerImage,
          logoImage: this.defaultSectionValue[option.id-1].logoImage ?? option.logoImage,
          ReferralUrl: this.defaultSectionValue[option.id-1].ReferralUrl ?? option.ReferralUrl,
          curated: IDs.join(","),
        };
      })
      //console.log(params);
      var str = Object.keys(params).map(function(key) {
            return key + '=' + encodeURI(JSON.stringify(params[key]))
        }).join('&');
      this.previewURL += '&'+str;
      //console.log(this.previewURL);
    },

    getECodes(brand) {
      axios.get('/newsletters/'+brand.toLowerCase()+'/api/getCodes.htm')
        .then((response) => {
          // handle success
          this.ecodes = response.data.data;
          this.numberOfSections = Object.keys(this.ecodes[0]).filter(k => k.includes('section')).length;

          //console.log(response.data.data);
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error.message;
          return Promise.reject(error)
        });
    },

    getCategory() {
      axios.get('/newsletters/techrepublic/api/getCategory.htm')
        .then((response) => {
          // handle success
          this.topics = response.data.data;
          //console.log(response.data.data);
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error.message;
          return Promise.reject(error)
        });
    },

    getContentType() {
      axios.get('/newsletters/techrepublic/api/getContentType.htm')
        .then((response) => {
          // handle success
          this.contentType = response.data.data;
          //console.log(response.data.data);
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error.message;
          return Promise.reject(error)
        });
    },

    updateLayout(val){
      if(this.selectedEcode){
        this.setDefaultOptions(val);
        this.constructPreviewURL();
      }
    },

    updateSelected(data) {
      //console.log(id);
      this.defaultSectionValue[data.id-1].count = data.selectedCount;
      this.defaultSectionValue[data.id-1].header = data.selectedHeader;
      this.defaultSectionValue[data.id-1].headerImage = data.selectedHeaderImageUrl;
      this.defaultSectionValue[data.id-1].logoImage = data.selectedLogoImageUrl;
      this.defaultSectionValue[data.id-1].ReferralUrl = data.selectedReferralUrl;
      this.defaultSectionValue[data.id-1].topics = [];
      data.selectedTopics.forEach(element => {
        if (element.id) this.defaultSectionValue[data.id-1].topics.push(element.id);
      });

      this.constructPreviewURL();
      //console.log(data);

      let updatedOption = this.options.find(op => op.id === data.id)
      this.snackbartext = updatedOption.sectionTitle +' Updated.';
      this.snackbartype = 'success';
      this.snackbar = true;
    },

    resetCurateContent(data) {
      let params = [];
      this.searchedCType = this.searchedTopics = this.searchedKeyword = this.searchedSlug = null;
      this.searchedPremium = '';
      params.push("count="+data.selectedCount);
      params.push("page=1");
      this.dialogData = data;
      if (data.selectedTopics) {
        var IDs=[];
        data.selectedTopics.forEach(element => {
          IDs.push(element.id);
        });
        params.push("category="+encodeURIComponent(IDs.join(",")));
      }
      //console.log('search param', params.join("&"));
      axios.get('/newsletters/techrepublic/api/getSearch.htm?'+(params?params.join("&"):''))
        .then((response) => {
          // handle success
          //console.log('Search headers', response.data.header.TotalPages);
          //console.log(response.data.data);

          this.curatedFullStory[this.dialogData.id-1].splice(0,this.curatedFullStory[this.dialogData.id-1].length);
          this.curatedStory[this.dialogData.id-1].splice(0,this.curatedStory[this.dialogData.id-1].length);

          let idStr = [];
          response.data.data.forEach(element => {
            this.curatedStory[this.dialogData.id-1].push(element.id);
            idStr.push(element.id+":"+element.type);
          });
          this.getStories(idStr.join(","), this.dialogData.id, idStr.length);

          this.snackbartext = 'Section '+data.id+' default listing added to the curation list.';
          this.snackbartype = 'success';
          this.snackbar = true;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error.message;
          return Promise.reject(error)
        });
    },

    curateContent(data) {
      this.dialogData = data;
      this.dialog = true;
      //console.log(data);
    },

    searchContents() {
      this.loading = true;
      var params = [];
      if (this.searchPerPage) params.push("count="+this.searchPerPage);
      if (this.premium) params.push("premium="+this.premium);

      if (this.searchKeyword) params.push("keyword="+encodeURIComponent(this.searchKeyword));
      if (this.searchId) params.push("keywordId="+encodeURIComponent(this.searchId));
      if (this.searchSlug) params.push("keywordSlug="+encodeURIComponent(this.searchSlug));
      if (this.searchCType && typeof this.searchCType === 'object')
        params.push("contentType="+encodeURIComponent(this.searchCType.value));
      else if (this.searchCType) {
        var Types=[];
        this.searchCType.forEach(element => {
          Types.push(element.value);
        });
        params.push("contentType="+encodeURIComponent(Types.join(",")));
      }
      if(
        ((!this.searchedCType && this.searchCType) || ((this.searchedCType &&  this.searchCType) && this.searchedCType.name != this.searchCType.name)) ||
        ((!this.searchedKeyword && this.searchKeyword) || (this.searchedKeyword != this.searchKeyword)) ||
        ((!this.searchedSlug && this.searchSlug) || (this.searchedSlug != this.searchSlug)) ||
        (this.premium != this.searchedPremium)
      )
        this.searchPageNum = 1;

      this.searchedCType = JSON.parse(JSON.stringify(this.searchCType))
      this.searchedKeyword = JSON.parse(JSON.stringify(this.searchKeyword))
      this.searchedSlug = JSON.parse(JSON.stringify(this.searchSlug))
      this.searchedPremium = JSON.parse(JSON.stringify(this.premium))

      if (this.searchTopics) {
        var IDs=[];
        this.searchTopics.forEach(element => {
          IDs.push(element.id);
        });
        let joinedIDs = IDs.join(",");
        if((this.searchedTopics && this.searchedTopics != joinedIDs) || (!this.searchedTopics && joinedIDs)) this.searchPageNum = 1
        this.searchedTopics = JSON.parse(JSON.stringify(joinedIDs))
        params.push("category="+encodeURIComponent(joinedIDs));
      }
      else if(this.searchedTopics) this.searchedTopics = null

      if (this.searchPageNum) params.push("page="+this.searchPageNum);
      //console.log('search param', params.join("&"));
      axios.get('/newsletters/techrepublic/api/getSearch.htm?'+(params?params.join("&"):''))
        .then((response) => {
          // handle success
          this.searchResults = response.data.data;
          //console.log('Search headers', response.data.header.TotalPages);
          //console.log(response.data.data);
          this.searchQuery = response.data.url;
          this.resultPages =  response.data.header.TotalPages;
          this.resultTotal =  response.data.header.Total;
          this.displayTotal = response.data.header.DisplayTotal;
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error.message;
          return Promise.reject(error)
        });
    },

    getStories(idstr, i, cnt) {
      axios.get('/newsletters/techrepublic/api/getStories.htm?idstr='+idstr)
        .then((response) => {
          // handle success
          if (response.data.data.length!=cnt) {
            console.log('Reading '+cnt+' stories. query: /newsletters/techrepublic/api/getStories.htm?idstr=' +idstr);
            console.log('get story', response.data.data);
            this.snackbartext = 'Failed to retrieve total '+cnt+' stories. Please try again!';
            this.snackbartype = 'error';
            this.snackbar = true;
          }
          response.data.data.forEach(element => {
            //console.log('element', element)
            if (element.id) this.curatedFullStory[i-1].push(element);
          });

          this.constructPreviewURL() ;
          //console.log(this.curatedFullStory[i-1]);
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error.message;
          return Promise.reject(error)
        });
    },

    add2Section(item) {
      var i = this.dialogData.id;
      //console.log('Adding', item.id);
      if (item?.id && this.curatedStory[i-1].indexOf(item.id) === -1) {
        this.curatedFullStory[i-1].push(item);
        this.curatedStory[i-1].push(item.id);

        this.snackbartext = 'Story id:'+item.id+' has been added to Section '+i;
        this.snackbartype = 'success';
        this.snackbar = true;
        this.constructPreviewURL() ;
      } else {
        //alert('Story '+item.id+' already added to Section '+id);
        this.snackbartext = 'Story id:'+item.id+' already added to Section '+i;
        this.snackbartype = 'error';
        this.snackbar = true;
      }
    },

    removeContent(data) {
      let id = data.id;
      let section_id = data.section_id;
      this.curatedStory[section_id-1].splice(data.index,1);
      this.curatedFullStory[section_id-1].splice(data.index,1);

      this.constructPreviewURL() ;
      this.snackbartext = 'Story id:'+id+' removed.';
      this.snackbartype = 'success';
      this.snackbar = true;
    },

    doNothing: (event) => event.stopPropagation(),

    sortCurated(section, from, to) {
      if (from==to) return true;

      //console.log(section+","+from+","+to);
      //console.log(this.curatedStory[section-1]);

      this.curatedStory[section-1].splice(to>from?to+1:to, 0, this.curatedStory[section-1][from]);
      //console.log('insert:',  this.curatedStory[section-1]);
      this.curatedStory[section-1].splice(to>from?from:from+1, 1);
      //console.log('remove:',  this.curatedStory[section-1]);
      this.curatedFullStory[section-1].splice(to>from?to+1:to, 0, this.curatedFullStory[section-1][from]);
      this.curatedFullStory[section-1].splice(to>from?from:from+1, 1);


      this.constructPreviewURL() ;
      this.snackbartext = 'Sorting done.';
      this.snackbartype = 'success';
      this.snackbar = true;
    },

  },
};
</script>
<style lang="scss" scoped>
  .expansion-switch {
    margin: 0px;
    padding-top: 0px;
  }

  .url-code {
    .title {
      line-height: 30px;
      font-size: 14px !important;
      font-weight: 500 !important;
      text-align: left;
      color: gray;
      margin-right: 6px;
    }
    .url {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  } // /.url-code
</style>